<template>
    <section class="other">
        <!--<div>
            <el-button type="primary" size="small" @click="openDialog(null,'chong')">￥ 充值</el-button>
        </div>-->
        <div class="table-title" style="margin-top:0;">待处理账单</div>
        <r-e-table ref="communityTableRef1" :dataRequest="getHouseLeaserOtherBillsUnPaidApi" :columns="houseOtherBillColumns"
                   :height="300" show-summary :summary-method="getSummaries">
            <el-table-column slot="toolbar" label="操作" width="150">
                <template slot-scope="{ row }">
                    <div class="table-tools">
                        <span class="table-btn" @click="look(row)">详情</span>
                        <span class="table-btn" v-if="!row.tuiFang" @click="update(row)">修改</span>
                        <span class="table-btn" v-if="!row.tuiFang && getButtonMenu('CANCLE_BILL_BUTTON')" @click="openDialog(row,'he')">核销</span>
                    </div>
                </template>
            </el-table-column>
        </r-e-table>
        <div class="table-title" style="margin-top:0;">已处理账单</div>
        <r-e-table ref="communityTableRef2" :dataRequest="getHouseLeaserOtherBillsPaidApi" :columns="houseOtherBillColumns"
                   :height="300" show-summary :summary-method="getSummaries">
            <el-table-column slot="toolbar" label="操作" width="100">
                <template slot-scope="{ row }">
                    <div class="table-tools">
                        <span class="table-btn" @click="look(row)">详情</span>
                    </div>
                </template>
            </el-table-column>
        </r-e-table>

        <r-e-dialog title="详情" class="layer-contract-detail" :visible.sync="detailsDialogVisible" show-footer top="10vh" width="1700px"
                    @click-submit="detailsDialogVisible=false" @click-cancel=" detailsDialogVisible = false" @close="detailsDialogVisible = false">
            <bill-details ref="billDetails" :billData="billData"></bill-details>
        </r-e-dialog>

        <layer-write-off-bill ref="layerWriteOffBill" @clickCancel="clickCancel"/>

        <dialog-add-other-bill ref="dialogAddOtherBill" @clickCancel="clickCancel"/>
    </section>
</template>

<script>
    import { houseOtherBillColumns } from "@/views/rental-management/tenant-management/data";
    import * as houseApi from "@/api/house-resource";
    import { MessageInfo, MessageError, MessageSuccess} from "@custom/message";
    import {buttonCookies} from "@storage/cookies";
    import {numberFormat} from "../../../../../utils/custom";
    export default {
        name: "other",
        props: {
            tenantData: {
                type: Object,
                default: () => ({})
            }
        },
        data() {
            return {
                houseOtherBillColumns: houseOtherBillColumns(this),
                billData: null,
                detailsDialogVisible: false,
            };
        },
        components: {
            billDetails:()=>import("@/views/bill-management/rent-withdrawal/components/billDetails"),
            layerWriteOffBill: () => import("@/views/bill-management/payment-record/components/layer-write-off-bill"),
            dialogAddOtherBill: () => import("@/views/rental-management/house-resource/components/part/components/dialog-add-other-bill.vue"),
        },
        async created() {
            // 获取用户uuid
            const {uuid} = this.tenantData;
            this.uuid = uuid;
        },
        methods: {
            async getHouseLeaserOtherBillsUnPaidApi(params) {
                // 获取用户已处理账单
                params.uuid = this.uuid;
                return houseApi.getHouseLeaserOtherBillsUnPaidApi(params);
            },

            getHouseLeaserOtherBillsPaidApi(params) {
                // 获取用户已处理账单
                params.uuid = this.uuid;
                return houseApi.getHouseLeaserOtherBillsPaidApi(params);
            },

            handleSearch() {
                this.$refs["communityTableRef1"].getTableData();
                this.$refs["communityTableRef2"].getTableData();
            },

            openDialog(data, type) {
                this.$refs["layerWriteOffBill"].openDialog(data);
            },

            async clickCancel() {
                await this.$emit('getDeposit');
                await this.$emit('handleSearch');
                this.handleSearch();
            },

            look(data) {
                this.billData = data;
                this.detailsDialogVisible = true;
            },

            getButtonMenu(menu) {
                return JSON.parse(buttonCookies.get()).map(item => item.url).some(v => v === menu);
            },

            update(data) {
                this.$refs["dialogAddOtherBill"].openDialog(data);
            },

            getSummaries(param) {
                const {columns, data} = param;
                const sums = [];
                columns.forEach((column, index) => {
                    if (index === 0) {
                        sums[index] = '合计';
                        return;
                    }
                    const values = data.filter(item => item.stateCode !== 600604).map(item => Number(item[column.property]));
                    if (!values.every(value => isNaN(value))) {
                        sums[index] = values.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) return prev + curr;
                            else return prev;
                        }, 0);
                        sums[index] = numberFormat(sums[index]/100);
                    } else sums[index] = '';
                });
                return sums;
            }
        }
    }
</script>

<style scoped>

</style>